<template>
    <v-row>
        <v-dialog width="500" v-model="dialogRelacionarUsuario">
            <v-card>
                <v-card-title>Relacionar usuário</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-autocomplete hide-details label="* Usuário:" :items="selectUsuario" :search-input.sync="selectUsuarioFiltro" v-model="registro.id_usuario" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5" v-html="erro"/>
                </v-card-text>
                <v-card-actions><v-spacer/><v-btn text @click="relUsuarioSalvar">Salvar</v-btn></v-card-actions>
            </v-card>
        </v-dialog>

        <v-col cols="12">
            <!--<v-btn text><v-icon left>mdi-plus</v-icon>Relacionar responsável *</v-btn>
            <v-btn text><v-icon left>mdi-plus</v-icon>Relacionar aluno *</v-btn>
            <v-btn text><v-icon left>mdi-plus</v-icon>Relacionar turma *</v-btn>
            <v-btn text><v-icon left>mdi-plus</v-icon>Relacionar disciplina *</v-btn>-->
            <v-btn text @click="dialogRelacionarUsuario = true"><v-icon left>mdi-plus</v-icon>Relacionar usuário</v-btn>
        </v-col>

        <v-col cols="12" class="pt-0">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="permissao('franquia_editar')" exact :to="`/franquia/alterar/${id_franquia}`" icon class="py-4"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('franquia_excluir')" exact @click="excluir" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/franquia" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Franquia:</strong> {{franquia.franquia}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{this.id_franquia}}</p>
                            <p class="text--primary mb-2" v-if="franquia.cidade"><strong>Endereço:</strong> {{franquia.logradouro}} {{franquia.numero ? 'Nº' : ''}} {{franquia.numero}}, {{franquia.bairro}} - {{franquia.cidade}} {{franquia.uf}}</p>
                            <p class="text--primary mb-2" v-if="franquia.cep"><strong>CEP:</strong> {{franquia.cep}}</p>
                            <p class="text--primary mb-2" v-if="franquia.razao_social_nome_completo"><strong>Razão Social / Nome completo:</strong> {{franquia.razao_social_nome_completo}}</p>
                            <p class="text--primary mb-2" v-if="franquia.cnpj_cpf"><strong>CNPJ /CPF:</strong> {{franquia.cnpj_cpf}}</p>
                            <p class="text--primary mb-2"><strong>Dia de vencimento da fatura:</strong> {{franquia.vencimento_fatura}}</p>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2" v-if="franquia.email"><strong>E-mail:</strong> <a :href="`mailto:${franquia.email}`">{{franquia.email}}</a></p>
                            <p class="text--primary mb-2" v-if="franquia.telefone"><strong>Telefone:</strong> {{franquia.telefone}}</p>
                            <p class="text--primary mb-2" v-if="franquia.whatsapp"><strong>WhatsApp:</strong> {{franquia.whatsapp}}</p>
                            <p class="text--primary mb-2" v-if="franquia.facebook"><strong>Facebook:</strong> {{franquia.facebook}}</p>
                            <p class="text--primary mb-2" v-if="franquia.instagram"><strong>Instagram:</strong> {{franquia.instagram}}</p>
                            <p class="text--primary mb-2" v-if="franquia.ativo"><strong>Ativa:</strong> {{franquia.ativo == 't' ? 'Sim' : 'Não'}}</p>
                            <p class="text--primary mb-2"><strong>Cadastrada em:</strong> {{dataPt(franquia.datahora_cadastro)}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows dark background-color="primary">
                <v-tab>Responsáveis</v-tab>
                <v-tab>Alunos</v-tab>
                <v-tab>Faturas</v-tab>
                <!--<v-tab>* Turmas</v-tab>
                <v-tab>* Disciplinas</v-tab>
                <v-tab>* Aulas</v-tab>
                <v-tab>* Vocabulário</v-tab>-->
                <v-tab>Usuários</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <ResponsavelListar :filtro="{id_franquia : parseInt(id_franquia)}"/>
                </v-tab-item>
                <v-tab-item>
                    <FranquiaAlunoListar :filtro="{id_franquia : parseInt(id_franquia)}"/>
                </v-tab-item>
                <v-tab-item>
                    <FinanceiroFaturaListar :filtro="{id_franquia : parseInt(id_franquia)}"/>
                </v-tab-item>
                <!--<v-tab-item>
                    <TurmaListar/>
                </v-tab-item>
                <v-tab-item>
                        <DisciplinaListar/>
                </v-tab-item>
                <v-tab-item>
                        <AulaListar/>
                </v-tab-item>
                <v-tab-item>
                        /* Listar palavras buscadas da disciplina em questão */
                </v-tab-item>-->
                <v-tab-item>
                        <UsuarioListar ref="usuarioListar" :filtro="{id_franquia : parseInt(id_franquia)}"/>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>

//import AulaListar from "@/Views/Aula/Component/AulaListar"
//import TurmaListar from "@/Views/Turma/Component/TurmaListar"
import UsuarioListar from "@/Views/Usuario/Component/UsuarioListar"
//import DisciplinaListar from "@/Views/Disciplina/Component/DisciplinaListar"
import axios from "axios"
import {mapState} from "vuex"
import ResponsavelListar from "@/Views/Responsavel/Component/ResponsavelListar"
import FinanceiroFaturaListar from "@/Views/Financeiro/Component/FinanceiroFaturaListar"
import FranquiaAlunoListar from "@/Views/Franquia/Component/FranquiaAlunoListar.vue"

export default {
    name: "FranquiaGerenciar",
    components: {FranquiaAlunoListar, FinanceiroFaturaListar, ResponsavelListar, /*DisciplinaListar,*/ UsuarioListar/*, TurmaListar, AulaListar*/},
    props : ['id_franquia'],
    data() {
        return {
            franquia:{},

            registro : {},
            tab : null,
            dialogRelacionarUsuario : false,
            erro : null,
            selectUsuario : [],
            selectUsuarioFiltro : ''
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}franquia/get`, {id_franquia : this.id_franquia}).then( (res) => {
                this.franquia = res.data
            })
        },
        excluir() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if(c) {
                return axios.post(`${this.baseUrl}franquia/excluir`, {id_franquia : this.id_franquia}).then( (res) => {
                    if (res.data) {
                        this.$router.push({path:'/franquia'})
                    } else {
                        alert("Erro ao excluir registro")
                    }
                })
            }
        },
        relUsuarioSalvar() {
            return axios.post(`${this.apiUrl}franquia/rel/usuario/novo`, {...this.registro, id_franquia : parseInt(this.id_franquia)}).then( (res) => {
                if(res.data) {
                    this.dialogRelacionarUsuario = false
                    this.registro = {}
                    this.erro = null
                    this.$refs.usuarioListar.paginar()
                }
            })
        }
    },
    watch: {
        selectUsuarioFiltro(v) {
            axios.post(`${this.baseUrl}usuario/listar`, {busca : v}).then((res) => {
                this.selectUsuario = res.data.lista.map((u) => {
                    return {value : parseInt(u.id_usuario), text : u.usuario}
                })
            })
        }
    },
    activated() {
        this.get()
    }
}
</script>

<style scoped>

</style>
