<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="alunos.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Aluno</th>
                        <th>Responsável</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(r, i) in alunos.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" v-if="r.imagem_perfil">
                                <img v-auth-image="`${apiUrl}usuario/${r.id_usuario}/imagem/thumb.jpg`"/>
                            </v-avatar>
                            <v-avatar size="40" v-else :color="corAvatar(r.nome_completo)">
                                <span class="white--text headline">{{r.nome_completo.substring(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td :key="r.id_aluno">
                            <router-link exact color="primary" v-if="permissao('aluno_abrir')" :to="`/aluno/gerenciar/${r.id_aluno}`"><strong>{{r.nome_completo}}</strong></router-link>
                            <strong v-else>{{r.nome_completo}}</strong><br />
                            <small><strong>ID:</strong> {{r.id_aluno}}</small>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('responsavel_abrir')" :to="`/responsavel/gerenciar/${r.responsavel.id_responsavel}`"><strong>{{r.responsavel.nome_completo}}</strong></router-link>
                            <strong v-else>{{r.responsavel.nome_completo}}</strong>
                        </td>
                        <td>
                            <v-chip small color="green" dark v-if="r.ativo"><v-icon left small>mdi-check-circle</v-icon> Ativo</v-chip>
                            <v-chip small color="red" dark v-else><v-icon left small>mdi-close-circle</v-icon> Inativo</v-chip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="alunos.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "FranquiaAlunoListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            alunos : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['apiUrl', 'paginationLimit'])
    },
    methods : {
       paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.apiUrl}franquia/aluno/listar`, {
                limit          : this.paginationLimit,
                offset         : this.paginationLimit * pagina,
                busca          : this.filtro.busca || null,
                id_franquia    : this.filtro.id_franquia || null
            }).then( (res) => {
                this.alunos = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_franquia' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
