<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="responsaveis.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Responsável</th>
                        <th class="hidden-sm-and-down">Alunos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(r, i) in responsaveis.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" :color="corAvatar(r.razao_social_nome_completo)">
                                <span class="white--text headline">{{r.razao_social_nome_completo.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td :key="r.id_responsavel">
                            <router-link exact color="primary" v-if="permissao('responsavel_abrir')" :to="`/responsavel/gerenciar/${r.id_responsavel}`"><strong>{{r.razao_social_nome_completo}}</strong></router-link>
                            <strong v-else>{{r.razao_social_nome_completo}}</strong><br />
                            <small><strong>ID:</strong> {{r.id_responsavel}}</small>
                        </td>
                        <td class="hidden-sm-and-down">{{r.aluno_total}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="responsaveis.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "ResponsavelListar",
    props : ['filtro', 'meu'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            responsaveis : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['baseUrl', 'paginationLimit'])
    },
    methods : {
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.baseUrl}responsavel/listar`, {
                pagina,
                limit       : this.paginationLimit,
                offset      : this.paginationLimit * pagina,
                busca       : this.filtro.busca || null,
                id_franquia : this.filtro.id_franquia || null,
                meu         : this.meu || false
            }).then( (res) => {
                this.responsaveis = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_franquia' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
